import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../../../services/data.service';
import {throwError as observableThrowError,  Observable } from 'rxjs';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit {

  

  public holidays = {
    title : <any>'',
    start_date : <any>'',
    end_date : <any>''
  }
  _req = <any>'';
  get_holidays = <any>'';



  public url = 'api/reservations/ManageAddHoliday'
  public url_ = 'api/reservations/ManageGetHolidays'
  public url_delect_holiday = 'api/reservations/ManageDelHoliday'
  
  constructor(
    private http: Http,
    private router: Router,
    private dataservice : DataService 
  ) {

  }

  async saveHoliday(){
    // console.log(this.holidays)
    let req = await this.dataservice.coreApi(this.url, this.holidays, 'post')
    this._req = JSON.parse(req._body);
    await this.alertmessage(this._req);
    this.getHoliday()
  }
  

  async alertmessage(value: any){
    if(value.status == true){
        //SUCCESS
        alert(value.message);
    }else{
        //ERROR
         alert(value.message);
    }
}

async getHoliday(){
    let req = await this.dataservice.coreApi(this.url_, this.holidays, 'post')
    this.get_holidays = JSON.parse(req._body);
    this.get_holidays = this.get_holidays.data
}

async delectholidays(value: number){
  let Data = {
    "holiday_id" : value
  }
  console.log(value)
    let req = await this.dataservice.coreApi(this.url_delect_holiday, Data, 'post')
    let _req = JSON.parse(req._body);
    this.getHoliday();
    this.alertmessage(_req)
}


p: number = 1;
collection: any[] = this.get_holidays;

ngOnInit() {  
  this.getHoliday()
}   

  

}