import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-account-hotel',
  templateUrl: './table-account-hotel.component.html',
  styleUrls: ['./table-account-hotel.component.scss']
})
export class TableAccountHotelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
