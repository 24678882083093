import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { DataService } from '../services/data.service'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { map } from "rxjs/operators";
import "rxjs/Rx";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid
} from "ng-apexcharts";
import { element } from 'protractor';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-statview',
  templateUrl: './statview.component.html',
  styleUrls: ['./statview.component.scss']
})
export class statviewComponent implements OnInit {

  public chartOptions: Partial<ChartOptions>;

  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private dataService: DataService
  ) { 

  }

  stavkview = {
    today : [],
    last_week : [],
    last_month :  [],
    total :  [],
    total_unique : [],
  }

  exampleFormControlSelect1 = <any>'';

  Listview = {
    days : <any>'',
    months : <any>'',
    years : <any>'',
  }

  public statview = <any>'statyear';
  categoriesOfgraph = <any>'';
  dataOfgraph = <any>'';

  data_ = [];
  data___ = [];
  graph = {
    month : []
  }

  statMonth = {
    day : [],
    dataofday : []
  }

  month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  month_stat = [
                    {
                      month: "Jan",
                      no: "1"
                    },
                    {
                      month: "Feb",
                      no: "2"
                    },
                    {
                      month: "Mar",
                      no: "3"
                    },
                    {
                      month: "Apr",
                      no: "4"
                    },
                    {
                      month: "May",
                      no: "5"
                    },
                    {
                      month: "Jun",
                      no: "6"
                    },
                    {
                      month: "Jul",
                      no: "7"
                    },
                    {
                      month: "Aug",
                      no: "8"
                    },
                    {
                      month: "Sep",
                      no: "9"
                    },
                    {
                      month: "Oct",
                      no: "10"
                    },
                    {
                      month: "Nov",
                      no: "11"
                    },
                    {
                      month: "Dev",
                      no: "12"
                    },
              ]

  datenow = Date();
  lastmonth = {
    firstday : new Date(),
    lastday : new Date()
  }
  min_lastweek = new Date();
  max_lastweek = new Date();

  dataDashboard = <any>null;
  dataBooked = null;
  dataCommission = null;
  token = <any>'';
  list_stat = <any>'';
  selectMonth = <any>'';


   // get stat view
   public GetDatastatView(){
    let url = this.dataService.api+"/Dashboard/Affiliate/statview";
    // console.log(url)

    let SetData = {
        "token" : this.token
    }

    let options = new RequestOptions();
            return this.http.post(url, SetData ,options)
                .pipe(map(res => res.json()))
                .toPromise()
                .then(
                  data => [
                      this.list_stat = data,
                  ],
                  err => [
                    console.log(err)
                  ]
                ).then(()=>{
                      //set stat overview
                      this.setStatView();
                });
  }

  setStatView(){
    //set Last Week (7 days ago)
    let DateNow = new Date(this.datePipe.transform(this.datenow, 'yyyy-MM-dd'));
    this.min_lastweek.setMonth(DateNow.getMonth());         // 1 2 3 4 5 6 7 8 9 10 11 12
    this.min_lastweek.setFullYear(DateNow.getFullYear());   //2000 2005 2020
    this.min_lastweek.setDate(DateNow.getDate()-7);         // 1 2 3 4 .... 15 16 17 ... 21 22 23 ...30 31
    let DateNow_ = this.datePipe.transform(DateNow, 'yyyy-MM-dd');
    let min_lastweek_ = this.datePipe.transform(this.min_lastweek, 'yyyy-MM-dd');


    for(let i = 0; i <= this.list_stat.length-1; i++){
      let Date__ =  this.datePipe.transform(this.list_stat[i].date, 'yyyy-MM-dd');
      if(Date__  < DateNow_ && Date__ >= min_lastweek_){
          this.stavkview.last_week.push(this.list_stat[i]);
      }
    }

    //set last month
    this.lastmonth.firstday = new Date(DateNow.getFullYear(), DateNow.getMonth()-1, 1);
    let firstday_ = this.datePipe.transform(this.lastmonth.firstday, 'yyyy-MM-dd');

    this.lastmonth.lastday = new Date(DateNow.getFullYear(), DateNow.getMonth()-1, 0);
    let lastday_ = this.datePipe.transform(this.lastmonth.lastday, 'yyyy-MM-dd');

    for(let i = 0; i <= this.list_stat.length-1; i++){
      let Date__ =  this.datePipe.transform(this.list_stat[i].date, 'yyyy-MM-dd');
      if(Date__  <= firstday_ && Date__ >= lastday_){
          this.stavkview.last_month.push(this.list_stat[i]);
      }
    }

    //set today
     for(let i = 0; i <= this.list_stat.length-1; i++){
      let Date__ =  this.datePipe.transform(this.list_stat[i].date, 'yyyy-MM-dd');
      if(Date__  == DateNow_){
          this.stavkview.today.push(this.list_stat[i]);
      }
    }

    //set total
    this.stavkview.total = this.list_stat.length;

    //set total unique
    let ipaddress = []
    let ipaddress_ = []
    for(let i = 0; i <= this.list_stat.length-1; i++){
      ipaddress.push(this.list_stat[i].ip_address)
    }
    this.stavkview.total_unique = Array.from(new Set(ipaddress))

    //set Array 1-12 Month 
    let month_
    let req = [];
    let date__ = <any>'';
    for(let i = 1; i <= 12; i++){
      let month_array = [];
        for(let j = 0; j <= this.list_stat.length-1; j++){
          let DateNow_ = new Date(this.datePipe.transform(this.list_stat[j].date, 'yyyy-MM-dd'));
          date__ = DateNow_.getMonth()+1;
          if(i == date__){
            month_array.push(this.list_stat[j]);
            // req.push(month_array);
          }
          
        }
        this.data_.push(month_array.length);
    }

    this.data___ = this.data_;
    this.dataOfgraph = this.data___;
    this.categoriesOfgraph = this.month;
      // // this.graph.month = req;
      // console.log(this.data_)
      this.CreateDataView();
  }

  setDataofStatMonth(){
    // set Day of Month
    this.statMonth.day = [];
    this.statMonth.dataofday = [];
    let DateNow = new Date();
    let firstday = new Date(DateNow.getFullYear(), this.selectMonth-1, 1);
    let firstday_ = firstday.getDate();
    let lastday = new Date(DateNow.getFullYear(), this.selectMonth, 0);
    let lastday_ = lastday.getDate();;
    for(let i = firstday_; i <= lastday_; i++){
        this.statMonth.day.push(i);
    }

    // get Stat for month
    let selectMonth = lastday.getMonth();
    let selectMonth_ = [];
    for(let i = 0; i <= this.list_stat.length-1; i++){
      let Date__ = new Date(this.list_stat[i].date);
      let Date_ = Date__.getMonth();
      if(selectMonth == Date_){
        selectMonth_.push(this.list_stat[i]);
      }
    }

    // set Stat to Day
    for(let i = 0; i <= this.statMonth.day.length-1; i++){
      let day_ = [];
      for(let j = 0; j <= selectMonth_.length-1; j++){
        let Date__ = new Date(this.list_stat[j].date);
        let Date_ = Date__.getDate();
        if(this.statMonth.day[i] == Date_){
          day_.push(this.list_stat[j])
        }
      }
      this.statMonth.dataofday.push(day_.length)
    }

      this.dataOfgraph = this.statMonth.dataofday;
      this.categoriesOfgraph = this.statMonth.day;
      this.CreateDataView();
  }

  CreateDataView(){
    this.chartOptions = {
      series: [
        {
          name: "users",
          data: this.dataOfgraph
        }
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        // text: "Month",
        // align: "left"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: this.categoriesOfgraph
      }
    };
  }

  ckeckSelectMain(){
    if(this.statview == 'statyear'){
      this.dataOfgraph = this.data___;
      this.categoriesOfgraph = this.month;
        this.CreateDataView();
    }
  }


  ngOnInit() {
    let _getUserData = JSON.parse(localStorage.getItem('users'));
    this.token =  _getUserData.data.token;
    this.GetDatastatView();
  }

}
