import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { join } from 'path';
import { DataService } from '../../../../services/data.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { map } from "rxjs/operators";
// -------------
import "rxjs/Rx";

declare var $: any;

@Component({
  selector: 'app-add-tours',
  templateUrl: './add-tours.component.html',
  styleUrls: ['./add-tours.component.scss']
})
export class AddToursComponent implements OnInit {
  filesToUpload:string [] = [];
  
  myForm = new FormGroup({
   name: new FormControl('', [Validators.required, Validators.minLength(3)]),
   file: new FormControl('', [Validators.required])
 });
  
  fileToUpload: File = null;
  fileToUpload_: File = null;
  fileToUpload_pdf: File = null;
  fileToUpload_cover: File = null;
  privatepax = ['1-3 pax','4-5 pax','6-9 pax'];
  _req = <any>'';

  ante_meridiem_tour_time  = <any>'a.m.';
  post_meridiem_tour_time = <any>'p.m.';
  post_meridiem_pickup = <any>'a.m.';

  public tour_time : any[] = [{
    meridiem: '',
    time_start: '',
    time_end: '',
    pickup_time: '',
    meridiem_start: 'a.m.',
    meridiem_end: 'a.m.',
    meridiem_pickup: 'a.m.'
  }];

  public tour_includes : any[] = [{
    include: ''
  }];
  public tour_excludes : any[] = [{
    exclude: ''
  }];
  public important_note : any[] = [{
    important: ''
  }];
  public itinerary : any[] = [{
    itinerary_time: '',
    itinerary_title: ''
  }];

  public join_price = {
    adult_price_join: <any>'',
    child_price_join: <any>'',
    adult_net_price_join: <any>'',
    child_net_price_join: <any>''
  }
  public private_price = [
    {
      adult_price_private: <any>'',
      child_price_private: <any>'',
      adult_net_price_private: <any>'',
      child_net_price_private: <any>''
    },
    {
      adult_price_private: <any>'',
      child_price_private: <any>'',
      adult_net_price_private: <any>'',
      child_net_price_private: <any>''
    },
    {
      adult_price_private: <any>'',
      child_price_private: <any>'',
      adult_net_price_private: <any>'',
      child_net_price_private: <any>''
    }
  ]


  public tour = {
    tour_code: <any>'',
    tour_name: <any>'',
    tour_concept: <any>'',
    tour_description: <any>'',
    tour_keyword: <any>'',
    tour_duration: <any>'',
    time_type: <any>'0',
    tour_categories: <any>'0',
    tour_time: this.tour_time,
    tour_itinerary: this.itinerary,
    tour_includes: this.tour_includes,
    tour_excludes: this.tour_excludes,
    tour_important_note: this.important_note,
    tour_reserve: <any>'',
    period_start: <any>'',
    period_end: <any>'',
    price: {
      "join": this.join_price,
      "private": this.private_price
    },
    image_header: <any>'',
    images_slide: <any>'',
    "photo": this.fileToUpload
  }

  public url = 'api/reservations/ManageAddTour'
  public url_upload = 'api/reservations/ManageUpload'
  public url_uploads = 'api/reservations/ManageUploads'
  public url_uploadpdf = 'api/reservations/ManageUploadPDF'
  public url_upload_cover = 'api/reservations/ManageUploadCover'

  constructor(
    private http: Http,
    private router: Router,
    private dataservice : DataService 
  ) { 
        
  }

onFileChange(event) {
  this.filesToUpload = [];
    for (var i = 0; i < event.target.files.length; i++) { 
        this.filesToUpload.push(event.target.files[i]);
    }
}

handleFileInput(files: FileList) {
  this.fileToUpload = files.item(0);
}

handleFileInput_(files: FileList) {
  this.fileToUpload_pdf = files.item(0);
}

handleFileCoverInput(files: FileList){
  this.fileToUpload_cover = files.item(0);
}

async saveTour(){
  let req = await this.dataservice.coreApi(this.url, this.tour, 'post')
  this._req = JSON.parse(req._body)
  // .then(()=>{this.uploadFileToActivity()})
  // .then(()=>{this.uploadFileCoverToActivity()})
  // .then(()=>{this.uploadFilesToActivity()})
  // .then(()=>{this.uploadPDFFileToActivity()})

  if(this.filesToUpload.length !== 0){
    this.uploadFilesToActivity()
  }
  if(this.fileToUpload !== null){
    this.uploadFileToActivity()
  }
  if(this.fileToUpload_cover !== null){
    this.uploadFileCoverToActivity()
  }
  if(this.fileToUpload_pdf !== null){
    this.uploadPDFFileToActivity()
  }

  // .then(()=>{if(this.url_upload !== null){
  //    this.uploadFileToActivity();
  // }})
  // .then(()=>{if(this.url_uploadpdf !== null){
  //     this.uploadPDFFileToActivity();
  // }})
  // .then(()=>{if(this.filesToUpload !== []){
  //     this.uploadFilesToActivity();
  // }})
  // .then(()=>{if(this.url_upload_cover !== null){
  //   this.uploadFileCoverToActivity();
  // }})

  await this.alertmessage(this._req);
}

alertmessage(value : any){
  if(value.status == true){
      //SUCCESS
      alert(value.message);
  }else{
      //ERROR
      alert(value.message);
  }
}

uploadFileToActivity() {
  this.dataservice.postFile(this.url_upload, this.fileToUpload, this.tour.tour_code)
}

uploadPDFFileToActivity() {
  this.dataservice.postPDFFile(this.url_uploadpdf, this.fileToUpload_pdf, this.tour.tour_code)
}

uploadFilesToActivity() {
    for (let i = 0; i < this.filesToUpload.length; i++) {
      this.dataservice.postsFile(this.url_uploads, this.filesToUpload[i], this.tour.tour_code)
    }
}

uploadFileCoverToActivity() {
  this.dataservice.postFile(this.url_upload_cover, this.fileToUpload_cover, this.tour.tour_code)
}

private handleError(error: Response){
  return observableThrowError(error.statusText);
}

  addAarray(value: number){
    if(value == 0){
      this.tour_time.push({
        meridiem: '',
        time_start: '',
        time_end: '',
        pickup_time: '',
        meridiem_start: 'a.m.',
        meridiem_end: 'a.m.',
        meridiem_pickup: 'a.m.'
      });
    }
    if(value == 2){
      this.tour_includes.push({
        include: ''
      });
    }
    if(value == 3){
      this.tour_excludes.push({
        exclude: ''
      });
    }
    if(value == 4){
      this.important_note.push({
        important: ''
      });
    }
    if(value == 5){
      this.itinerary.push({
        itinerary_time: '',
        itinerary_title: ''
      });
    }
  }

  removeArray(value: number, i: number){
    if(value == 0){
      this.tour_time.splice(this.tour_time.length-1, 1);
    }
    if(value == 2){
      this.tour_includes.splice(this.tour_includes.length-1, 1);
    }
    if(value == 3){
      this.tour_excludes.splice(this.tour_excludes.length-1, 1);
    }
    if(value == 4){
      this.important_note.splice(this.important_note.length-1, 1);
    }
    if(value == 5){
      this.itinerary.splice(this.itinerary.length-1, 1);
    }
  }

  // clickevent(){
  //   $('.register-btn').click(function(){
  //         $('.f-upload').click();
  //   });
  // }

  ngOnInit() {
    // this.clickevent();
  }

}
