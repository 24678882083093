import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../../services/data.service'

@Component({
  selector: 'app-add-account-hotel',
  templateUrl: './add-account-hotel.component.html',
  styleUrls: ['./add-account-hotel.component.scss']
})
export class AddAccountHotelComponent implements OnInit {

  private operate_users : any[] = [{
    name: '',
    position: '',
    tel: ''
  }];

  private hotel_email : any[] = [{
    email: '',
  }];

  private addUser = {
    account: <any>"",
    accountName: <any>"",
    accountType: <any>"",
    username: <any>"",
    password: <any>"",
    fullname: <any>"",
    email: <any>""
  };

  private account = {
    date: <any>"",
    sale_name: <any>"",
    customer_code: <any>"",
    hotel_name: <any>"",
    operate_user: this.operate_users,
    email: this.hotel_email,
    cash: <any>"",
    collect_at: <any>'',
    note: <any>"",
    note_by: <any>""
  }

  private accountType = [];
  private accountType_ = <any>"";

  constructor(
    private http: Http,
    private router: Router,
    private dataservice : DataService 
  ) { }

  addoperateusers() {
    this.operate_users.push({
      name: '',
      position: '',
      tel: ''
    });
  }

  addhotelemail() {
    this.hotel_email.push({
      email: '',
    });
  }

  removeoperateusers(i: number) {
    this.operate_users.splice(i, 1);
  }

  removehotelemail(i: number) {
    this.hotel_email.splice(i, 1);
  }

  saveData(){
    
  }

  ngOnInit() {
  }

}
