
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Component, Injectable, Output } from '@angular/core';
import { map } from "rxjs/operators";
// -------------
import { Http, Response, RequestOptions } from "@angular/http";
import "rxjs/Rx";

// Interfaces
import { SaveBookingInterface } from "./../interfaces/save-booking-interface";
import { Form } from '@angular/forms';

@Injectable()
export class DataService {

  // private _saveBooking = "http://localhost:9000/api/ReservationSaveBookingData";
  private _saveBooking = "http://api.tourinchiangmai.com/api/ReservationSaveBookingData";

  @Output() api : string;

  req = <any>'';

  constructor(private http: Http) { 
    this.api = "http://localhost:8000/";
    // this.api = "http://api.tourinchiangmai.com/";
  }


  coreApi(url: string, data: object, method :string){
    let options = new RequestOptions();
    url = this.api+url;
    return this.http[method](url,data,options)
        .pipe(map(res => res))
        .toPromise()
        .then(
          data => [
              this.req = data,
          ],
          err => [
            console.log(err)
          ]
        ).then(()=>{
            return this.req
        });
  }


//from add tours main images
  postFile(url: string, fileToUpload: File, tourcode: string) {
    url = this.api+url;
    let options = new RequestOptions();
    const formData: FormData = new FormData();
    formData.append('photo', fileToUpload, fileToUpload.name);
    formData.append('tourcode', tourcode);
    return this.http
      .post(url, formData, options)
      .pipe(map(() => { res=>res.json(); }))
      .toPromise()
      .then(data => [
        this.req = data,
       ])
       .then(()=>{
        return this.req
        })
      .catch((e) => this.handleError(e));
}

postPDFFile(url: string, fileToUpload: File, tourcode: string) {
  url = this.api+url;
  let options = new RequestOptions();
  const formData: FormData = new FormData();
  formData.append('pdf', fileToUpload, fileToUpload.name);
  formData.append('tourcode', tourcode);
  return this.http
    .post(url, formData, options)
    .pipe(map(() => { res=>res.json(); }))
    .toPromise()
    .then(data => [
      this.req = data,
     ])
     .then(()=>{
      return this.req
      })
    .catch((e) => this.handleError(e));
}

//form add tours multi images
postsFile(url: string, fileToUpload: string, tourcode: string) {
  url = this.api+url;
  let options = new RequestOptions();
  const formData: FormData = new FormData();
  formData.append('photo', fileToUpload);
  formData.append('tourcode', tourcode);
  return this.http
    .post(url, formData, options)
    .pipe(map(() => { return true; }))
    .toPromise()
    .then(data => [
      this.req = data,
     ])
     .then(()=>{
      return this.req
      })
    .catch((e) => this.handleError(e));
}

  // get data booking
  saveDataBooking(_dataSave){
    // return this._saveBooking;
    // return this.http
    //   .get(this._saveBooking)
    //   .map((response: Response) => {
    //     return <SaveBooking.BookingInfo>response;
    //   })
    //   .catch(this.handleError);
    // return this.http.post(url: _saveBooking, body: any, option?: )
  }

  private handleError(error: Response){
    return observableThrowError(error.statusText);
  }

  public get(key: any, params: any){

  }
}
