import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {throwError as observableThrowError,  Observable } from 'rxjs';


@Component({
  selector: 'app-update-tours',
  templateUrl: './update-tours.component.html',
  styleUrls: ['./update-tours.component.scss']
})
export class UpdateToursComponent implements OnInit {

  fileToUpload: File = null;
  fileToUpload_: File = null;
  fileToUpload_pdf: File = null;
  filesToUpload:string [] = [];
  fileToUpload_cover: File = null;
  privatepax = ['1-3 pax','4-5 pax','6-9 pax'];

  ante_meridiem_tour_time  = <any>'a.m.';
  post_meridiem_tour_time = <any>'p.m.';
  post_meridiem_pickup = <any>'a.m.';

  public tour_time : any[] = [];

  public tour_includes : any[] = [];
  public tour_excludes : any[] = [];
  public important_note : any[] = [];
  public itinerary : any[] = [];

  public join_price = {
    adult_price_join: <any>'',
    child_price_join: <any>'',
    adult_net_price_join: <any>'',
    child_net_price_join: <any>''
  }
  public private_price = [
    {
      adult_price_private: <any>'',
      child_price_private: <any>'',
      adult_net_price_private: <any>'',
      child_net_price_private: <any>''
    },
    {
      adult_price_private: <any>'',
      child_price_private: <any>'',
      adult_net_price_private: <any>'',
      child_net_price_private: <any>''
    },
    {
      adult_price_private: <any>'',
      child_price_private: <any>'',
      adult_net_price_private: <any>'',
      child_net_price_private: <any>''
    }
  ]

  public tour = {
    tour_id: <any>'',
    tour_code: <any>'',
    tour_name: <any>'',
    tour_concept: <any>'',
    tour_description: <any>'',
    tour_keyword: <any>'',
    tour_duration: <any>'',
    time_type: <any>'0',
    tour_categories: <any>'0',
    tour_time: this.tour_time,
    tour_itinerary: this.itinerary,
    tour_includes: this.tour_includes,
    tour_excludes: this.tour_excludes,
    tour_important_note: this.important_note,
    tour_reserve: <any>'',
    period_start: <any>'',
    period_end: <any>'',
    price: {
      "join": this.join_price,
      "private": this.private_price
    },
    image_header: <any>'',
    images_slide: <any>'',
    image_cover: <any>'',
    galleries: <any>'',
    document: <any>'',
    "photo": this.fileToUpload
  }

  constructor(
    private dataservice : DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public tourcode = <any>'';
  public Data = <any>'';
  public _getTours = <any>'';
  public _req = <any>'';

  public url = 'api/reservations/ManageUpdateTour'
  public url_ = "api/Website/tours/getTours";
  public url_Delect_image = "api/reservations/Manage/Delect/Image"
  public url_Delect_header = "api/reservations/Manage/Delect/ImageHeader"
  public url_Delect_pdf = "api/reservations/Manage/Delect/Pdf"
  public url_upload = 'api/reservations/ManageUpload'
  public url_uploads = 'api/reservations/ManageUploads'
  public url_uploadpdf = 'api/reservations/ManageUploadPDF'
  public url_upload_cover = 'api/reservations/ManageUploadCover'



    // 1. print
    public print():void {
      window.print();
    }
  
    // 2. active menu
    public activeMenu(){
      // set storage
      sessionStorage.setItem('menu',JSON.stringify(5));
      sessionStorage.setItem('sub-menu',JSON.stringify(501));
    }

    onFileChange(event) {
      this.filesToUpload = [];
        for (var i = 0; i < event.target.files.length; i++) { 
            this.filesToUpload.push(event.target.files[i]);
        }
    }
    
    handleFileInput(files: FileList) {
      this.fileToUpload = files.item(0);
    }
    
    handleFileInput_(files: FileList) {
      this.fileToUpload_pdf = files.item(0);
    }

    handleFileCoverInput(files: FileList){
      this.fileToUpload_cover = files.item(0);
    }
    

    async updateTours(){
      // console.log(this.tour)
      let req = await this.dataservice.coreApi(this.url, this.tour, 'post')
      this._req = JSON.parse(req._body)
        if(this.filesToUpload.length !== 0){
          this.uploadFilesToActivity()
        }
        if(this.fileToUpload !== null){
          this.uploadFileToActivity()
        }
        if(this.fileToUpload_cover !== null){
          this.uploadFileCoverToActivity()
        }
        if(this.fileToUpload_pdf !== null){
          this.uploadPDFFileToActivity()
        }
        
        // .then(()=>{this.uploadPDFFileToActivity()})
        // .then(()=>{this.uploadFileToActivity()})
        // .then(()=>{this.uploadFilesToActivity()})

        await this.alertmessage(this._req);
    }

    alertmessage(value : any){
      if(value.status == true){
          //SUCCESS
          alert(value.message);
          window.location.reload()
      }else{
          //ERROR
          alert(value.message);
      }
    }

    
uploadFileToActivity() {
  this.dataservice.postFile(this.url_upload, this.fileToUpload, this.tour.tour_code)
}

uploadPDFFileToActivity() {
  this.dataservice.postPDFFile(this.url_uploadpdf, this.fileToUpload_pdf, this.tour.tour_code)
}

uploadFileCoverToActivity() {
  this.dataservice.postFile(this.url_upload_cover, this.fileToUpload_cover, this.tour.tour_code)
}

uploadFilesToActivity() {
    for (let i = 0; i < this.filesToUpload.length; i++) {
      this.dataservice.postsFile(this.url_uploads, this.filesToUpload[i], this.tour.tour_code)
    }
}

private handleError(error: Response){
  return observableThrowError(error.statusText);
}

  async getTours(){
    this.Data = {
      "TourCode" : this.tourcode
    }

    let req = await this.dataservice.coreApi(this.url_, this.Data, 'post')
    this._getTours = await JSON.parse(req._body);
    await console.log( this._getTours.data)
    await this.setFormData();
    await this.setLastOverview();
  }

  //get dont set from
  async getTours_(){
    this.Data = {
      "TourCode" : this.tourcode
    }

    let req = await this.dataservice.coreApi(this.url_, this.Data, 'post')
    this._getTours = await JSON.parse(req._body);
    this.tour.image_header = this._getTours.data.image;
    this.tour.galleries = this._getTours.data.galleries;
    this.tour.document = this._getTours.data.document;
  }

  async setFormData(){
    this.tour.tour_id = this._getTours.data.tour_id;
    this.tour.tour_code = this._getTours.data.tour;
    this.tour.tour_name = this._getTours.data.title;
    this.tour.tour_concept = this._getTours.data.concept;
    this.tour.tour_description = this._getTours.data.description;
    this.tour.tour_keyword = this._getTours.data.keywords;
    this.tour.tour_duration = this._getTours.data.duration;
    this.tour.time_type = this._getTours.data.timetype;
    this.tour.tour_categories = this._getTours.data.categories;
    this.tour.tour_reserve = this._getTours.data.reserve;
    this.tour.period_start = this._getTours.data.period_start;
    this.tour.period_end = this._getTours.data.period_end;
    this.tour.image_cover = this._getTours.data.image_cover;

    // period_start: <any>'',
    // period_end: <any>'',

    //set tour time
    if(this._getTours.data.tourtime){
    for(let i = 0; i <= this._getTours.data.tourtime.length-1; i++){
      this.tour_time.push({
        meridiem: this._getTours.data.tourtime[i].meridiem,
        time_start: this._getTours.data.tourtime[i].travel_time_start.substring(0,5),
        time_end: this._getTours.data.tourtime[i].travel_time_end.substring(0,5),
        pickup_time: this._getTours.data.tourtime[i].pickup_time.substring(0,5),
        meridiem_start: this._getTours.data.tourtime[i].travel_time_start.substring(6),
        meridiem_end: this._getTours.data.tourtime[i].travel_time_end.substring(6),
        meridiem_pickup: this._getTours.data.tourtime[i].pickup_time.substring(6)
      });
    }
  }else{
    this.tour_time.push({
      meridiem: '',
      time_start: '',
      time_end: '',
      pickup_time: '',
      meridiem_start: 'a.m.',
      meridiem_end: 'a.m.',
      meridiem_pickup: 'a.m.'
    });
  }

  //set itinerary
  if(this._getTours.data.itinerary){
  for(let i = 0; i <= this._getTours.data.itinerary.length-1; i++){
    this.itinerary.push({
      itinerary_time: this._getTours.data.itinerary[i].time,
      itinerary_title: this._getTours.data.itinerary[i].detail
    });
  }
}else{
  this.itinerary.push({
    itinerary_time: '',
    itinerary_title: ''
  });
}

//set includes
if(this._getTours.data.itinerary){
  for(let i = 0; i <= this._getTours.data.includes.length-1; i++){
    this.tour_includes.push({
      include: this._getTours.data.includes[i].include
    });
  }
}else{
  this.tour_includes.push({
    include: ''
  });
}

//set excludes
if(this._getTours.data.excludes){
  for(let i = 0; i <= this._getTours.data.excludes.length-1; i++){
    this.tour_excludes.push({
      exclude: this._getTours.data.excludes[i].exclude
    });
  }
}else{
  this.tour_excludes.push({
    exclude: ''
  });
}

//set important
if(this._getTours.data.important){
  for(let i = 0; i <= this._getTours.data.important.length-1; i++){
    this.important_note.push({
      important: this._getTours.data.important[i].important
    });
  }
}else{
  this.important_note.push({
    important: ''
  });
}

//set Join & Provate Sell Price
// ---- join
this.join_price.adult_price_join = this._getTours.data.price.join.adult
this.join_price.child_price_join = this._getTours.data.price.join.child
// ---- pricate
this.private_price[0].adult_price_private = this._getTours.data.price.private[0].adult
this.private_price[0].child_price_private = this._getTours.data.price.private[0].child
this.private_price[1].adult_price_private = this._getTours.data.price.private[1].adult
this.private_price[1].child_price_private = this._getTours.data.price.private[1].child
this.private_price[2].adult_price_private = this._getTours.data.price.private[2].adult
this.private_price[2].child_price_private = this._getTours.data.price.private[2].child

//set Join & Provate Net Price
// ---- join
this.join_price.adult_net_price_join = this._getTours.data.price.join.adult_net
this.join_price.child_net_price_join = this._getTours.data.price.join.child_net
// ---- pricate
this.private_price[0].adult_net_price_private = this._getTours.data.price.private[0].adult_net
this.private_price[0].child_net_price_private = this._getTours.data.price.private[0].child_net
this.private_price[1].adult_net_price_private = this._getTours.data.price.private[1].adult_net
this.private_price[1].child_net_price_private = this._getTours.data.price.private[1].child_net
this.private_price[2].adult_net_price_private = this._getTours.data.price.private[2].adult_net
this.private_price[2].child_net_price_private = this._getTours.data.price.private[2].child_net

this.tour.image_header = this._getTours.data.image;
this.tour.galleries = this._getTours.data.galleries;
this.tour.document = this._getTours.data.document;

  }

  setLastOverview(){
    this.tour.tour_time = this.tour_time;
    // console.log(this.tour)
  }

  addAarray(value: number){
    if(value == 0){
      this.tour_time.push({
        meridiem: '',
        time_start: '',
        time_end: '',
        pickup_time: '',
        meridiem_start: 'a.m.',
        meridiem_end: 'a.m.',
        meridiem_pickup: 'a.m.'
      });
    }
    if(value == 2){
      this.tour_includes.push({
        include: ''
      });
    }
    if(value == 3){
      this.tour_excludes.push({
        exclude: ''
      });
    }
    if(value == 4){
      this.important_note.push({
        important: ''
      });
    }
    if(value == 5){
      this.itinerary.push({
        itinerary_time: '',
        itinerary_title: ''
      });
    }
  }

  removeArray(value: number, i: number){
    if(value == 0){
      this.tour_time.splice(this.tour_time.length-1, 1);
    }
    if(value == 2){
      this.tour_includes.splice(this.tour_includes.length-1, 1);
    }
    if(value == 3){
      this.tour_excludes.splice(this.tour_excludes.length-1, 1);
    }
    if(value == 4){
      this.important_note.splice(this.important_note.length-1, 1);
    }
    if(value == 5){
      this.itinerary.splice(this.itinerary.length-1, 1);
    }
  }

  async delectImage(){
    let code = {
      TourCode : this.tour.tour_code
    }
    let req = await this.dataservice.coreApi(this.url_Delect_header, code, 'post')
    let req_ = JSON.parse(req._body);
    if(req_.status == true){
      this.getTours_()
    }else{
      alert(req_.message)
    }
  }

  async delectImages(images_name: string){
    let DataImages = {
      image_name : images_name
    }
    let req = await this.dataservice.coreApi(this.url_Delect_image, DataImages, 'post')
    let req_ = JSON.parse(req._body);
    if(req_.status == true){
      this.getTours_()
    }else{
      alert(req_.message)
    }
  }

  async delectPDF(){
    let code = {
      TourCode : this.tour.tour_code
    }
    let req = await this.dataservice.coreApi(this.url_Delect_pdf, code, 'post')
    let req_ = JSON.parse(req._body);
    if(req_.status == true){
      this.getTours_()
    }else{
      alert(req_.message)
    }
  }


  ngOnInit() {
    this.tourcode = this.route.snapshot.paramMap.get('id');
    this.getTours();
  }

}
