import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {

  constructor(
    private dataservice : DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public tour_active = <any>'';
  public tour_code = <any>'';
  public Data = <any>'';
  public _getTours = <any>'';
  url = "api/reservations/ManageGetTours";
  url_active = "api/reservations/ManageToursActive";
  url_message = "api/reservations/ManageToursActiveMessage";
  url_holidays = "api/reservations/ManageGetHolidays";
  url_holidays_ID = "api/reservations/ManageGetHolidaysbyID";
  url_update_holidays = "api/reservations/ManageUpdateHolidays"
  url_delete_tour = "api/reservations/ManageToursDelete"

  is_booking : boolean = false;
  is_active : boolean =  false;
  booking_status : boolean = false;
  TourDelect =  <any>'';
  message_respon =  <any>'';
  _holidays = <any>'';
  __holidays = <any>'';
  tour_code_holiday = <any>'';
  ArrayHolidays: Array<any> = [];
  ArrayHolidays_: Array<any> = [];
  _req = <any>'';
  message = {
    "active" : <any>'',
    "booking" : <any>''
  }
  
  // 1. print
  public print():void {
    window.print();
  }

  // 2. active menu
  public activeMenu(){
    // set storage
    sessionStorage.setItem('menu',JSON.stringify(5));
    sessionStorage.setItem('sub-menu',JSON.stringify(501));
  }


  async getTours(){
    let req = await this.dataservice.coreApi(this.url, this.Data, 'post')
    this._getTours = JSON.parse(req._body); 
  }

  async setToursStatus(tourCode: string){
      this.tour_code = tourCode;
      // this.tour_active = this._getTours.find(element => element.tour = tourCode);
      for(let i = 0; i <= this._getTours.length-1; i++){
        if(this.tour_code == this._getTours[i].tour){
          this.tour_active = this._getTours[i];
          this.message_respon = this._getTours[i].message_alert
        }
      }
      console.log(this.tour_active)
      // console.log(this.tour_active)
      if(this.tour_active.is_booking == 1){
        this.is_booking = true;
        this.message.booking = "ON";
        this.booking_status = false;
      }
      if(this.tour_active.is_booking == 0){
        this.is_booking = false;
        this.message.booking = "OFF";
        this.booking_status = true;
      }
      if(this.tour_active.is_active == 1){
        this.is_active = true;
        this.message.active = "ON";
      }
      if(this.tour_active.is_active == 0){
        this.is_active = false;
        this.message.active = "OFF";
      }  

      this.openModel();
  }

  openModel(){
    $('#myModal').on('shown.bs.modal', function () {
      $('#myInput').trigger('focus')
    })
  }

  toggleActive(value: string){
    if(value == 'active'){
      if(this.is_active == true){
        this.is_active = false;
        this.message.active = "OFF";

        this.is_booking = false;
        this.message.booking = "OFF";
        this.booking_status = true;

        this.saveChangeActive('is_active', this.is_active)
        this.saveChangeActive('is_booking', this.is_booking)
      }else{
        this.is_active = true;
        this.message.active = "ON";
        this.saveChangeActive('is_active', this.is_active)
      }
    }

    if(value == 'booking'){
      if(this.is_booking == true){
        this.is_booking = false;
        this.message.booking = "OFF";
        this.booking_status = true;
        this.saveChangeActive('is_booking', this.is_booking)
      }else{
        this.is_booking = true;
        this.message.booking = "ON";
        this.booking_status = false;
        this.message_respon = '';
        this.saveChangeActive('is_booking', this.is_booking)
      }
    }
  }

  async updateRespon(){
    let Data = {
      "TourCode" : this.tour_code,
      "Message" : this.message_respon
    }
    let req = await this.dataservice.coreApi(this.url_message , Data, 'post')
    this._req = JSON.parse(req._body)
    this.alertmessage_(this._req);
  }

  async saveChangeActive(key: string, value: boolean){
    let Data = {
      "TourCode" : this.tour_code,
      "key_active" : key,
      "value" : value,
    }
    let req = await this.dataservice.coreApi(this.url_active, Data, 'post')
    this._req = JSON.parse(req._body)
     this.alertmessage(this._req);
  }

  async alertmessage(value : any){
    if(value.status == false){
        //SUCCESS
        alert(value.message);
    }
    this.getTours();


  }

  async alertmessage_(value: any){
      if(value.status == true){
          //SUCCESS
          alert(value.message);
      }else{
          //ERROR
           alert(value.message);
      }
    this.getTours();
  }


  // ----------------------------- holidays -----------------------------------
  async getHoildays(){
    let Data = <any>'';
    let req = await this.dataservice.coreApi(this.url_holidays , Data, 'post')
    this._holidays = JSON.parse(req._body)
    for(let i = 0; i <= this._holidays.data.length-1; i++){
        this.ArrayHolidays.push({"holiday_id" : this._holidays.data[i].id , "status" : false , "holiday" : this._holidays.data[i].holiday , "holiday_type_id" : 1 , "period_start" : this._holidays.data[i].period_start , "period_end" : this._holidays.data[i].period_end});
    }
  }

  async getHoildaysbyId(tour_code){
    // this.ArrayHolidays = this.ArrayHolidays;
    this.tour_code_holiday = tour_code;
    let Data_ = {
      "TourCode" : tour_code
    };
    let req_ = await this.dataservice.coreApi(this.url_holidays_ID , Data_, 'post')
    this.__holidays = JSON.parse(req_._body)
    // console.log(this.ArrayHolidays)
    // console.log(this.__holidays)
    //set holiday By Id
    for(let i = 0; i <= this.ArrayHolidays.length-1; i++){
      for(let j = 0; j <= this.__holidays.data.length-1; j++){
        if(this.ArrayHolidays[i].holiday_id == this.__holidays.data[j].holidays.id){
          this.ArrayHolidays[i].status = true;
          break;
        }else{
          this.ArrayHolidays[i].status = false;
        }
      }
    }
    // console.log(this.__holidays)
    // console.log(this.ArrayHolidays)
  }

  checkAllholidays(value){
    if(value == true){
    for(let i = 0 ; i <= this.ArrayHolidays.length-1; i++){
        this.ArrayHolidays[i].status = true
      }
    }
    if(value == false){
      for(let i = 0 ; i <= this.ArrayHolidays.length-1; i++){
          this.ArrayHolidays[i].status = false
        }
      }
  }

  checkholidays(value, id){
    // this.updateholidays(value,id);
    for(let i = 0 ; i <= this.ArrayHolidays.length-1; i++){
      if(this.ArrayHolidays[i].holiday_id == id){
        this.ArrayHolidays[i].status = value
      }
    }
      console.log(this.ArrayHolidays)
    // console.log(value+' / '+id);
  }

  async updateholidays(){
    let ArrayHolidays_ = [];
    for(let i = 0 ; i <= this.ArrayHolidays.length-1; i++){
      if(this.ArrayHolidays[i].status == true){
        ArrayHolidays_.push(this.ArrayHolidays[i]);
      }
    }
    let Data = {
      "TourCode" : this.tour_code_holiday,
      "holidays" : ArrayHolidays_
    };
    console.log(Data)
    let req = await this.dataservice.coreApi(this.url_update_holidays , Data, 'post')
    req= JSON.parse(req._body)
    this.alertmessage_(req)
  }

  setTourDelete(value: string){
    this.TourDelect = value;
  }

  async deleteTour(){
    let Data = {
      "TourCode" : this.TourDelect
    }
    let req = await this.dataservice.coreApi(this.url_delete_tour , Data, 'post')
    req = JSON.parse(req._body)
    this.clickevent();
    this.alertmessage_(req)
  }

  clickevent(){
    $('delete-tour').click(function(){
          $('close').click();
    });
    $('cancel-delete-tour').click(function(){
          $('close').click();
  });


  }


  //------------------ End Page ------------------------
  ngOnInit() {
    this.activeMenu();
    this.getTours();
    this.openModel();
    this.getHoildays();
    this.clickevent();
  }

}
