import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions, ResponseContentType } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from "rxjs/operators";
import { saveAs } from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-get-images-aff',
  templateUrl: './get-images-aff.component.html',
  styleUrls: ['./get-images-aff.component.scss']
})
export class GetImagesAffComponent implements OnInit {

  constructor(
    private http    : Http,
    private router  : Router,
  ) { }

  public DataImages = <any>'';
  public userToken = <any>'';
  public LinkDownload = <any>'';
  public selectTour_ = <any>'';
  public ListTour = <any>'';
  public FileSaver = <any>'';

  public imageset1 = [];
  public imageset2 = [];
  public imageset3 = [];
  public imageset4 = [];
  public imageset5 = [];


  tourLink = [
    {
      "id": 0,
      "tour_code" : "allTours",
      "tour": "All Tours",
      "link": "https://tour-in-chiangmai.com/tours"
    },
    {
      "id": 1,
      "tour_code" : "TC-R01",
      "tour": "TC-R01 : Doi Suthep Temple & Hmong Hill Tribe Village ",
      "link": "https://tour-in-chiangmai.com/tour/TC-R01"
    },
    {
      "id": 2,
      "tour_code" : "TC-R02",
      "tour": "TC-R02 : City Temple & Museum",
      "link": "https://tour-in-chiangmai.com/tour/TC-R02"
    },
    {
      "id": 3,
      "tour_code" : "TC-R03",
      "tour": "TC-R03 : Khan Toke Dinner & Market Exploring ",
      "link": "httpS://tour-in-chiangmai.com/tour/TC-R03"
    },
    {
      "id": 4,
      "tour_code" : "TC-R04",
      "tour": "TC-R04 : Chiang Rai Day Trip",
      "link": "https://tour-in-chiangmai.com/tour/TC-R04"
    },
    {
      "id": 5,
      "tour_code" : "TC-R05",
      "tour": "TC-R05 : Inthanon National Park",
      "link": "https://tour-in-chiangmai.com/tour/TC-R05"
    },
    {
      "id": 6,
      "tour_code" : "TC-R06",
      "tour": "TC-R06 : Trekking in Doi Suthep Area",
      "link": "https://tour-in-chiangmai.com/tour/TC-R06"
    },
    {
      "id": 7,
      "tour_code" : "TC-R07",
      "tour": "TC-R07 : Eco Walk in Kiew Mae Pan Nature Trail",
      "link": "https://tour-in-chiangmai.com/tour/TC-R07"
    },
    {
      "id": 8,
      "tour_code" : "TC-E01",
      "tour": "TC-E01 : Morning Buddhist Life",
      "link": "https://tour-in-chiangmai.com/tour/TC-E01"
    },
    {
      "id": 9,
      "tour_code" : "TC-E02",
      "tour": "TC-E02 : Hill Tribe Ways & Evening Chanting",
      "link": "https://tour-in-chiangmai.com/tour/TC-E02"
    },
    {
      "id": 10,
      "tour_code" : "TC-E03",
      "tour": "TC-E03 : The Rhythm of Chiang Mai",
      "link": "https://tour-in-chiangmai.com/tour/TC-E03"
    },
    {
      "id": 11,
      "tour_code" : "TC-E04",
      "tour": "TC-E04 : Appreciation of Celedon Making",
      "link": "https://tour-in-chiangmai.com/tour/TC-E04"
    },
    {
      "id": 12,
      "tour_code" : "TC-E05",
      "tour": "TC-E05 : Understand Local Life & Wisdom ",
      "link": "https://tour-in-chiangmai.com/tour/TC-E05"
    },
    {
      "id": 13,
      "tour_code" : "TC-E06",
      "tour": "TC-E06 : Variety of Lampang",
      "link": "https://tour-in-chiangmai.com/tour/TC-E06"
    },
  ];

  getImagesAff(){
      // let url = 'http://localhost:8000/api/Dashboard/Affiliate/GetImages/Tours';
    //  let url = 'http://api.tourinchiangmai.com/api/Dashboard/Affiliate/GetImages/Tours';
    let url = './assets/json/affiliate/images/images.json'


        return this.http.get(url)
                .pipe(map(res => res.json()))
                .toPromise()
                .then(
                  data => [
                      this.DataImages = data,
                  ],
                  err => [
                    console.log(err)
                  ]
                ).then(()=>{
                    // this.setDataImages();
                });
  }

  setDataImages(){
    this.imageset1 = [];
    this.imageset1.push(this.ListTour.path_image[0]);
    this.imageset1.push(this.ListTour.path_image[1]);
    this.imageset1.push(this.ListTour.path_image[2]);
    this.imageset1.push(this.ListTour.path_image[3]);
    this.imageset1.push(this.ListTour.path_image[4]);

    this.imageset2 = [];
    this.imageset2.push(this.ListTour.path_image[5]);
    this.imageset2.push(this.ListTour.path_image[6]);
    this.imageset2.push(this.ListTour.path_image[7]);
    this.imageset2.push(this.ListTour.path_image[8]);
    this.imageset2.push(this.ListTour.path_image[9]);

    this.imageset3 = [];
    this.imageset3.push(this.ListTour.path_image[10]);
    this.imageset3.push(this.ListTour.path_image[11]);
    this.imageset3.push(this.ListTour.path_image[12]);
    this.imageset3.push(this.ListTour.path_image[13]);
    this.imageset3.push(this.ListTour.path_image[14]);

    this.imageset4 = [];
    this.imageset4.push(this.ListTour.path_image[15]);
    this.imageset4.push(this.ListTour.path_image[16]);
    this.imageset4.push(this.ListTour.path_image[17]);
    this.imageset4.push(this.ListTour.path_image[18]);
    this.imageset4.push(this.ListTour.path_image[19]);

    this.imageset5 = [];
    this.imageset5.push(this.ListTour.path_image[20]);
    this.imageset5.push(this.ListTour.path_image[21]);
    this.imageset5.push(this.ListTour.path_image[22]);
    this.imageset5.push(this.ListTour.path_image[23]);
    this.imageset5.push(this.ListTour.path_image[24]);

  }

  check(){
    console.log(this.LinkDownload);
  }

  selectTour(){
    // check & get Tour
    for(let i=0; i <= this.DataImages.length-1; i++){
      if(this.selectTour_ == this.DataImages[i].tour_code){
          this.ListTour = this.DataImages[i]
      }
    }

    if(this.selectTour_ !== 'allTours'){
      this.setDataImages();
    }
    if(this.selectTour_ == 'allTours'){
      this.ListTour = [];
      this.imageset1 = [];
      this.imageset2 = [];
      this.imageset3 = [];
      this.imageset4 = [];
      this.imageset5 = [];
    }
  }

  getUserToken(){
    let getUser = JSON.parse(localStorage.getItem('users'));
    this.userToken = getUser.data.token;
  }

  downloadFile(){

  }

  

  ngOnInit() {
      this.getUserToken()
      this.getImagesAff();
  }
  

}
