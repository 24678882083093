import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-holidays-table',
  templateUrl: './holidays-table.component.html',
  styleUrls: ['./holidays-table.component.scss']
})
export class HolidaysTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
